import { styled } from '@/styles/theme'

export const Container = styled('div', {
  '& > form > div:first-child': {
    minHeight: 'calc(100dvh - 160px)',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',

    '@md': {
      minHeight: 'calc(100dvh - 80px)',
      height: 'fit-content',
      flexDirection: 'row',
    },
  },

  // [`${FormContainer}`]: {
  //   '& fieldset:last-child': {
  //     gap: '$4',

  //     '& > div:first-child': {
  //       gap: '$4',
  //     },
  //   },
  // },
})
