import { onlyNumbers, validateCPF } from '@/utils'
import { z } from 'zod'

export const pidDataFormSchema = z.object({
  cpf: z
    .string()
    .min(14, { message: 'CPF deve ter 11 dígitos' })
    .max(14, { message: 'CPF deve ter 11 dígitos' })
    .transform((cpf) => onlyNumbers(cpf))
    .refine((cpf) => validateCPF(cpf), { message: 'Informe um CPF válido' }),
  ticket: z
    .string()
    .min(5, { message: 'Informe um ticket válido' })
    .max(20, { message: 'Informe um ticket válido' }),
})

export type PIDFormData = z.infer<typeof pidDataFormSchema>
