import { zodResolver } from '@hookform/resolvers/zod'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { FormEvent, ReactElement } from 'react'
import { useForm } from 'react-hook-form'

import { FooterActions } from '@/components/FooterActions'
import { FormContainer } from '@/components/Form/FormContainer'
import { FormDescription } from '@/components/Form/FormDescription'
import { TextField } from '@/components/Form/TextField'
import { Text, Title } from '@/components/Typography'
import { useAppState } from '@/contexts/AppStateContext'
import { useValidateTicket } from '@/hooks/useValidateTicket'
import { AppLayout } from '@/layouts/AppLayout'
import type { NextPageWithLayout } from '@/pages/_app.page'
import { maskCPF, onlyNumbers } from '@/utils'
import { type PIDFormData, pidDataFormSchema } from './pidFormSchema'
import { Container } from './styles'

const Identification: NextPageWithLayout = () => {
  const router = useRouter()
  const {
    formState,
    setFormState,
    setCancelModalOpen,
    cancelModalOpen,
    setCurrentStep,
  } = useAppState()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<PIDFormData>({
    resolver: zodResolver(pidDataFormSchema),
    defaultValues: {
      cpf: maskCPF(formState.cpf),
      ticket: formState.ticket,
    },
  })

  function handleGoBack(event: FormEvent) {
    event.preventDefault()
    router.push('/ouvidoria/personal-data')
    setCurrentStep((prev) => prev - 1)
  }

  async function handleNextStep({ cpf, ticket }: PIDFormData) {
    const { isTicketValid } = await useValidateTicket(ticket, router)
    setFormState((prevState) => ({
      ...prevState,
      cpf,
      ticket,
      isTicketValid,
    }))

    if (!isTicketValid) {
      return router.push('/ouvidoria/sinto-muito')
    }

    setCurrentStep((prev) => prev + 1)
    router.push('/ouvidoria/mensagem')
  }

  return (
    <Container>
      <Head>
        <title>Ouvidoria | Flash</title>
      </Head>

      <form onSubmit={handleSubmit(handleNextStep)}>
        <div>
          <FormDescription>
            <Title as="legend" size="headline7">
              Fale com a ouvidoria da Flash
            </Title>
            <Text size="body3">
              Se a sua reclamação foi tratada em nossos canais de atendimento,
              mas a resposta não atendeu sua expectativa, a Ouvidoria Flash pode
              reavaliar seu pedido.
            </Text>
          </FormDescription>

          <FormContainer id="personal-data-form">
            <fieldset>
              <TextField
                type="text"
                autoComplete="on"
                label="CPF"
                error={errors.cpf?.message}
                placeholder="000.000.000-00"
                {...register('cpf', {
                  onChange(event) {
                    const { value } = event.target
                    event.target.value = maskCPF(value)
                  },
                })}
              />

              <TextField
                type="text"
                label="Ticket de atendimento"
                autoComplete="on"
                error={errors.ticket?.message}
                placeholder="0000000000"
                {...register('ticket', {
                  onChange(event) {
                    const { value } = event.target
                    event.target.value = onlyNumbers(value)
                  },
                })}
              />
            </fieldset>
          </FormContainer>
        </div>

        <FooterActions
          cancelModalOpen={cancelModalOpen}
          onCancelModalOpen={() => setCancelModalOpen((prev) => !prev)}
          onHandleGoBack={handleGoBack}
          primaryButtonDisabled={isSubmitting}
          loading={isSubmitting}
        />
      </form>
    </Container>
  )
}

Identification.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>
}

export default Identification
