import type { NextRouter } from 'next/router'

export async function useValidateTicket(ticket: string, router: NextRouter) {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_CUSTOMER_HELPER}/v1/ouvidoria/ticket/validate/${ticket}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        cache: 'no-cache',
      },
    )

    const result: { validate: boolean } = await response.json()

    return {
      isTicketValid: result.validate,
    }
  } catch (error) {
    router.push('/ouvidoria/sinto-muito')
  }
}
